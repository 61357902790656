.new {
    background-color: #44abdf;
    height: 44vh;
    /* background-color: #f3f5f6; */
    /* */

    /* margin-top: 1px; */

}

.new h2 {
    /* margin: 0; Add this line to reset the margin */
    color: #9c1818;
}
.new p {
    font-size: 19px;
    text-align: center;
}

.maindiv {
    max-width: 41rem;
    margin: auto;

    position: relative;
    top: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: pink;

}