.App {
  text-align: center;
}
#root{
overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.search input:focus {
  outline: none;
}
.search input {
  color: #9c1818;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.announcement-bar {
  background: #0d0e0e;
  color: #ffffff;
  padding: 0 2.5rem;
}
.announcement-bar__inner {
  display: flex;
  align-items: center;
}
.announcement-bar__inner_p {
  margin-right: auto;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;
  font-size: 0.95em;
}
.announcement-bar__inner_button {
  background-color: #9c1818;
  padding: 12px 20px 12px 20px;
  color: #ffffff;
  font: inherit;
  border: none;
  border-radius: 0;
}
.announcement-bar__inner_button svg {
  display: inline-block;
  width: 20px;
  height: 17px;
  margin-right: 12px;
  vertical-align: text-bottom;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 2.2rem;
  background-color: #9c1818;
}
.header__action-list {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.seller-p {
  font-weight: 900;
}

.logo {
  flex: 1;
}
.logo-img {
  width: 9rem;
  height: 1.6rem;
}

.logo-link {
  margin-left: 2.5rem;
}

.nav {
  background-color: #9c1818;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
}
.right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 39%;
}

.wishlist {
  display: flex;
  align-items: center;
  /* border-right: 0.5px solid whitesmoke;
  padding-right: 10px; */
}
.wishlist .header-navlink {
  display: flex;
  align-items: center;
}

.wishlist .header-navlink p {
  margin-left: 0.5rem;
}

.cart {
  display: flex;
  align-items: center;
}
.cart .header-navlink {
  display: flex;
  align-items: center;
}

.cart .header-navlink p {
  margin-left: 0.5rem;
}
.seller {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.right p {
  color: white;
}
.right svg {
  fill: white;
}
.search {
  width: 50%;
  padding: 11px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: roboto;
  gap: 0.5rem;
}
.search input {
  margin: 0px 8px;
  padding: 11.5px 11.5px;
  font-size: 1rem;
  width: 100%;
  border-radius: 5px 0px 0px 5px;
  border: 2px solid white;
  line-height: normal;
  font-family: roboto;
}

.search svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: white;
}

.header_count_span {
  color: white;
  font-size: 0.8rem;
}

.header-navlink {
  text-decoration: none;
}

.menuitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 60%;
}

.menuitem li {
  list-style: none;
}
.secnav {
 
  padding: 25px 47px;

}
.menuitem li a {
  text-decoration: none;
  color: black;
  padding: 0px 11px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.menuitem li a:hover {
  color: #9c1818;
  cursor: pointer;
}

.bottom {
  background-color: rgb(41, 40, 40);
  width: 108rem;
  height: 200rem;
}
.icon-user {
  width: 1.6rem;
  height: 1.2rem;
}
.mid-nav-mobile-view {
  color: white;
  display: none;
}
.mid-nav-mobile-view svg {
  width: 1.5rem;
  height: 2rem;
}
.secnav svg {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 767px) {
  .announcement-bar {
    padding: 0.3rem 0;
  }
  .announcement-bar__inner button {
    display: none;
  }
  .announcement-bar__inner_p {
    font-size: 0.8em;
    margin-right: 1rem;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
  }
  .announcement-bar__inner {
    justify-content: center;
  }
  .right p {
    display: none;
  }
  .nav {
    padding: 20px 0px;
    /* justify-content: normal; */
  }
  .right {
    width: 15%;
    margin-right: 3rem;
    gap: 0.6rem;
    margin-left: 7rem;
  }
  .logo-img {
    width: 7rem;
    height: 1.2rem;
  }
  .mid-nav-mobile-view {
    display: block;
  }
  .mid-nav-mobile-view svg{
    margin-left:1rem;
  }
  .logo-link{
    margin-left:1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .right p {
    display: none;
  }
  nav {
    padding: 18px 0px;
    /* justify-content: normal; */
  }
  .right {
    width: 15%;
    margin-right: 2rem;
    /* margin-left: 35rem; */
  }
  .icon-user {
    width: 1.6rem;
    height: 1.2rem;
  }
  .mid-nav-mobile-view {
    display: block;
  }
  .mid-nav-mobile-view svg{
    margin-left:3rem;
  }
  .logo-link{
    margin-left:1.2rem;
  }
}


