.ai-form-input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0 0 0 0;
  background: #fff;
  box-shadow: none;
  font-size: 32px;
  height: 40px;
  border-bottom: 2px solid #bfbcbc;
  border-radius: 0;
  color: #343434;
  font-weight: 400;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.ai-form-button {
  background-color: #9c1818;
  flex-shrink: 0;
  margin: 0 0 0 20px;
  height: 65px;
  padding: 0 20px 0 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 40px;
  white-space: normal;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  gap: 0.5rem;
}
.input-container {
  max-width: 980px;
  position: relative;
  padding: 30px;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.main-ai-form-div {
  max-width: 880px !important;
  margin-left: auto;
  margin-right: auto;
}

.main-div {
  margin: 8rem 0;
}

.ai-form-input:focus,
.ai-form-input:active {
  outline: none; 
}

@media screen and (max-width: 767px) {
  .input-container {
    max-width: 400px;
    margin: 0 auto 15px auto;
    padding: 25px;
    flex-wrap: wrap;
  }
  .ai-form-input{
    text-align: center;
    margin: 20px 0 20px 0;
    font-size: 20px;
  }
  .ai-form-button{
    height: 50px;
    width: 100%;
    justify-content: center;
    margin: 0;
  }
}
