.secnav{
  border-bottom: 2px solid #ebe7e7;
  z-index: 9999;
}
.allcat li{
list-style: none;
padding: 8px;
font-size: 1rem;
}
.about li{
list-style: none;
padding: 8px;
font-size: 1rem;
}

.allcat a {
  text-decoration: none;
  /* color: #9c1818; */
  font-weight: 400;
}
.about a {
  text-decoration: none;
  /* color: #9c1818; */
  font-weight: 400;
}

.allcat{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.about{
  display: flex;
  justify-content: center;
}

.collections li{
  list-style: none;
  padding: 8px;
  font-size: 1rem;

}

.resources li{
  list-style: none;
  padding: 8px;
  font-size: 1rem;

}

.collections {
  max-width: fit-content;
  /* background-color: rgb(243, 243, 243); */
  margin-left: 10rem;

}
.resources {
  max-width: fit-content;
  /* background-color: rgb(243, 243, 243); */
  margin-left: 40rem;

}
.aboutfirstrow{
  margin-right:  76px;
}
.aboutsecrow{
  margin-right:  76px;
}
.aboutthirdrow{
  margin-right:  76px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .bottom-navbar{
    display:none;
  }
}

@media (max-width: 767px) {
  .bottom-navbar{
    display:none;
  }
}


.collections ul li:hover {
  color:rgb(156, 25, 25);
  cursor:pointer
}
.resources ul li:hover {
  color:rgb(156, 25, 25);
  cursor:pointer
}